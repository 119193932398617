import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { StaticQuery, graphql, Link } from 'gatsby'
import { generateSlug } from '../helpers/slug'
import lang from '../../static/lang.json'
import vars from '../lib/styled'
import { filterByLocale } from '../helpers/iterators'
import Grid from './Grid'
import { CardSecondary } from './Card'

const CategoryList = styled.ul`
  background-color: ${vars.contrast};
  box-sizing: border-box;
  list-style: none;
  text-align: center;
  margin: 0;
  padding: 0 1vw;
  position: sticky;
  top: 0;
  width: 100%;
  z-index: 100;

  @media (max-width: ${vars.breakpoint.tablet}) {
    position: relative;
  }
`

const CategoryListItem = styled.li`
  display: inline-block;
  margin-bottom: 0;
  margin-right: 0;
  padding: 0;
`

const CategoryListItemLink = styled(Link)`
  background-color: transparent;
  border-bottom: 4px solid transparent;
  border-radius: 0;
  color: ${vars.secondary};
  display: block;
  font-weight: 400;
  font-size: 0.833rem;
  line-height: 3rem;
  text-decoration: none;
  padding: 0 1.5vw;
  transition-duration: 500ms;
  transition-property: background-color, border-color, color;

  &:hover,
  &.active {
    border-bottom-color: ${vars.accent};
    color: ${vars.accent};
  }
`

const query = graphql`
  query {
    cats: allContentfulCategory(sort: { fields: order, order: ASC }) {
      nodes {
        title
        contentful_id
        node_locale
        image {
          title
          fluid(maxWidth: 800) {
            ...GatsbyContentfulFluid
          }
        }
      }
    }
  }
`

const Categories = ({ locale, list }) => (
  <StaticQuery
    query={query}
    render={({ cats }) => {
      const nodes = filterByLocale(cats.nodes, locale)

      return list ? (
        <nav aria-label="subnav">
          <CategoryList>
            {nodes.map((category) => (
              <CategoryListItem key={category.contentful_id}>
                <CategoryListItemLink
                  activeClassName="active"
                  to={generateSlug(
                    [
                      locale,
                      lang.products[locale.toLowerCase()],
                      category.title,
                    ].join('/'),
                    true,
                  )}
                >
                  {category.title}
                </CategoryListItemLink>
              </CategoryListItem>
            ))}
          </CategoryList>
        </nav>
      ) : (
        <Grid>
          {nodes.map((category) => (
            <CardSecondary
              key={category.contentful_id}
              name={category.title}
              image={category.image}
              linkText={lang.productsView[locale.toLowerCase()]}
              url={generateSlug(
                [
                  locale,
                  lang.products[locale.toLowerCase()],
                  category.title,
                ].join('/'),
                true,
              )}
            />
          ))}
        </Grid>
      )
    }}
  />
)

Categories.propTypes = {
  locale: PropTypes.oneOf(['en-CA', 'en-US', 'fr-CA']).isRequired,
  list: PropTypes.bool,
}

Categories.defaultProps = {
  list: false,
}

export default Categories
