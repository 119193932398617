import React, { useState } from 'react';
import { graphql, StaticQuery } from 'gatsby';
import styled from 'styled-components';
import { filterByLocale } from '../helpers/iterators';
import vars from '../lib/styled';
import { generateSlug } from '../helpers/slug';
import lang from '../../static/lang.json';
import CardRecipe from './CardRecipe';
import Grid from './Grid';
import Button from './Button';
import { useLang } from './Locale';

export const query = graphql`
    query {
        recipes: allContentfulRecipe {
            nodes {
                node_locale
                contentful_id
                title
                serves
                time
                variant {
                    title
                    contentful_id
                    product {
                        title
                        contentful_id
                        category {
                            title
                            contentful_id
                        }
                    }
                }
                photo {
                    title
                    fluid(quality: 100) {
                        ...GatsbyContentfulFluid
                    }
                }
            }
        }
    }
`;

const CategoryFilter = styled.button.attrs({
    type: 'button',
})`
    background-color: ${({ active }) => (active ? vars.accent : vars.contrast)};
    border: 0;
    border-radius: 5px;
    color: ${({ active }) => (active ? vars.contrast : vars.secondary)};
    cursor: pointer;
    padding: 0.5rem 1.5rem;
    margin: 0.15rem 0 0 0.5rem;
    text-transform: uppercase;
    transition: background-color 500ms;

    &:hover,
    &:focus {
        background-color: ${vars.accent};
        color: ${vars.contrast};
    }
`;

export default ({ locale }) => {
    const resultsPerPage = 8;
    const t = useLang();
    const [pagination, setPagination] = useState(1);
    const [filter, setFilter] = useState(null);

    return (
        <StaticQuery
            query={query}
            render={({ recipes }) => {
                const results = filterByLocale(recipes.nodes, locale);
                const resultsToShow = results.filter(({ variant }) => {
                    if (!filter) return true;
                    if (!variant) return false;
                    let match = false;

                    variant.forEach(({ product }) => {
                        if (!product) return;
                        product.forEach(({ category }) => {
                            if (category.contentful_id === filter) {
                                match = true;
                            }
                        });
                    });

                    return match;
                });

                const categories = results
                    .map(({ variant }) => {
                        if (!variant) return null;
                        let cat;

                        variant.forEach(({ product }) => {
                            if (!product) return;
                            product.forEach(({ category }) => {
                                cat = category;
                            });
                        });

                        return cat;
                    })
                    .filter((arr) => arr !== null)
                    .reduce((acc, curr) => {
                        let match;
                        acc.forEach((obj) => {
                            if (!curr || !obj) return;
                            if (obj.contentful_id === curr.contentful_id) {
                                match = true;
                            }
                        });

                        if (!match) acc.push(curr);
                        return acc;
                    }, []);

                return (
                    <div>
                        <nav aria-label="filter">
                            <CategoryFilter
                                active={filter === null}
                                onClick={() => setFilter(null)}
                            >
                                {t('all')}
                            </CategoryFilter>
                            {categories.map((obj) => (
                                <CategoryFilter
                                    active={obj.contentful_id === filter}
                                    onClick={() => setFilter(obj.contentful_id)}
                                    key={obj.contentful_id}
                                >
                                    {obj.title}
                                </CategoryFilter>
                            ))}
                        </nav>
                        <p
                            aria-live="polite"
                            style={{ margin: '1rem .5rem -1rem' }}
                        >
                            <small>
                                {`${t('showing')} ${resultsToShow.length} ${t(
                                    'of',
                                )} ${results.length}`}
                            </small>
                        </p>
                        <Grid>
                            {resultsToShow.map((recipe, i) => {
                                const settings = {
                                    key: recipe.contentful_id,
                                    image: recipe.photo,
                                    url: generateSlug(
                                        [
                                            locale,
                                            t('recipes'),
                                            recipe.title,
                                        ].join('/'),
                                    ),
                                    button: t('recipesView'),
                                    ...recipe,
                                };

                                return (
                                    resultsPerPage * pagination > i && (
                                        <CardRecipe {...settings} />
                                    )
                                );
                            })}
                        </Grid>
                        {resultsPerPage * pagination < resultsToShow.length && (
                            <Button
                                primary
                                style={{ marginTop: '2rem' }}
                                onClick={() => setPagination(pagination + 1)}
                            >
                                Load More
                            </Button>
                        )}
                    </div>
                );
            }}
        />
    );
};
