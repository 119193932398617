import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import vars from '../lib/styled'

const CardWrapper = styled.figure`
  margin: 0;
  padding: 1rem 1.5rem;
  width: 33%;

  @media (max-width: ${vars.breakpoint.tablet}) {
    width: 50%;
  }
`

const CardImage = styled.div`
  background: #ddd;
  border-radius: 50%;
  height: 100px;
  margin: 0 auto 1rem auto;
  padding: 1.5rem;
  position: relative;
  width: 100px;

  img {
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 50%;
  }
`

const CardBody = styled.figcaption`
  text-align: center;
`

const CardTitle = styled.h2`
  color: ${vars.secondary};
  font-size: 1.211em;
  margin-bottom: -0.5rem;
`

const CardWithIcon = ({ title, description, image }) => (
  <CardWrapper>
    <CardImage>
      <img src={image.file.url} alt={image.title} />
    </CardImage>
    <CardBody>
      <CardTitle>{title}</CardTitle>
      <p>{description}</p>
    </CardBody>
  </CardWrapper>
)

CardWithIcon.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  image: PropTypes.shape({
    fluid: PropTypes.object,
    title: PropTypes.string,
  }).isRequired,
}

export default CardWithIcon
