import React from 'react'
import PropTypes from 'prop-types'
import { graphql, StaticQuery } from 'gatsby'
import { filterByLocale } from '../helpers/iterators'
import CardWithIcon from './CardWithIcon'
import Grid from './Grid'
import { ContainerSmall } from './Container'

export const query = graphql`
  query {
    highlights: allContentfulHighlight {
      nodes {
        node_locale
        contentful_id
        title
        description {
          description
        }
        icon {
          title
          file {
            url
          }
        }
      }
    }
  }
`

const Highlights = ({ locale }) => (
  <StaticQuery
    query={query}
    render={({ highlights }) => {
      const results = filterByLocale(highlights.nodes, locale)
      return (
        <ContainerSmall>
          <Grid style={{ justifyContent: 'center', marginBottom: '2rem' }}>
            {results.map((highlight) => {
              const settings = {
                key: highlight.contentful_id,
                title: highlight.title,
                image: highlight.icon,
                description: highlight.description.description,
              }

              return (
                <CardWithIcon {...settings} key={highlight.contentful_id} />
              )
            })}
          </Grid>
        </ContainerSmall>
      )
    }}
  />
)

Highlights.propTypes = {
  locale: PropTypes.string.isRequired,
}

export default Highlights
