import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import Container from './Container'
import Grid from './Grid'
import * as Accessa from 'accessa'

const VideoWrapper = styled.section`
  margin-top: 3rem;
  padding: 0 2vw;
`

const VideoPanel = styled.div`
  width: 32%;

  &:hover {
    img {
      filter: brightness(90%);
    }
  }

  button {
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  @media (max-width: 992px) {
    width: 49%;
  }

  @media (max-width: 768px) {
    width: 100%;
  }
`

const VideoImage = styled.div`
  border-radius: 2px;
  box-shadow: 3px 13px 15px #22222220;
  height: 250px;
  position: relative;
  width: 100%;

  img {
    height: 100%;
    object-fit: cover;
    position: relative;
    transition: filter 500ms;
    width: 100%;
  }
`

const VideoPanelTitle = styled.h2`
  font-size: 1.6em;
  margin: 1rem 0 2rem;
  text-align: center;
`

const VideoGallery = ({ videos }) => (
  <VideoWrapper>
    <Container>
      <Grid style={{ justifyContent: 'space-around' }}>
        <Accessa.Galleries.Lightbox lists={videos}>
          {(video, btn) => (
            <VideoPanel key={video.id}>
              <VideoImage>
                <img src={video.img} alt={video.title} />
                {btn}
              </VideoImage>
              <VideoPanelTitle>{video.title}</VideoPanelTitle>
            </VideoPanel>
          )}
        </Accessa.Galleries.Lightbox>
      </Grid>
    </Container>
  </VideoWrapper>
)

VideoGallery.propTypes = {
  videos: PropTypes.array.isRequired,
}

export default VideoGallery
