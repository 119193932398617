import styled from 'styled-components';
import vars from '../lib/styled';

export default styled.div`
    p { 
        font-weight: 200;
        font-size: 1.424em;
        line-height: 1.5;

        @media (max-width: ${vars.breakpoint.tablet}){
            font-size: 1.3em;
        }
    }
`;
