import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { SubHeading } from './Heading';
import { ButtonLink, ButtonLinkExternal } from './Button';
import vars from '../lib/styled';
import { extractHTML } from '../helpers/iterators';

const CallToActionWrapper = styled.section`
    margin: 0 auto;
    max-width: 100%;
    padding: 3rem 3rem 0;
    text-align: center;
    width: 850px;

    @media (max-width: ${vars.breakpoint.tablet}) {
        padding: 1rem;
    }
`;

const CallToAction = ({ button, subtitle, title, url, ...rest }) => (
    <CallToActionWrapper>
        <SubHeading>{subtitle}</SubHeading>
        <h3>{title}</h3>
        <div {...extractHTML(rest, 'body')} />

        {/** accepts both relative and absolute url paths */
        url.startsWith('http') ? (
            <ButtonLinkExternal href={url}>{button}</ButtonLinkExternal>
        ) : (
            <ButtonLink to={url} primary>
                {button}
            </ButtonLink>
        )}
    </CallToActionWrapper>
);

CallToAction.propTypes = {
    title: PropTypes.string.isRequired,
    subtitle: PropTypes.string.isRequired,
    body: PropTypes.shape({
        childContentfulRichText: PropTypes.object,
    }).isRequired,
    url: PropTypes.string,
    button: PropTypes.string,
};
CallToAction.defaultProps = {
    url: '/',
    button: 'Learn more',
};

export default CallToAction;
