import React from 'react'
import { graphql } from 'gatsby'
import App from '../components/App'
import Wrapper from '../components/Wrapper'
import Main from '../components/Main'
import CardKitchen from '../components/CardKitchen'
import CardMediaLess from '../components/CardMediaLess'
import Grid from '../components/Grid'
import Hero from '../components/Hero'
import Section from '../components/Section'
import Leader from '../components/Leader'
import HeroVariant from '../components/HeroVariant'
import { extractHTML } from '../helpers/iterators'
import Panel, { PanelContainer } from '../components/Panel'
import Questions from '../components/Questions'
import Categories from '../components/Categories'
import CallToAction from '../components/CallToAction'
import { HrefLangMeta } from '../components/Locale'
import Recipes from '../components/Recipes'
import Highlights from '../components/Highlights'
import GlutenFree from '../components/GlutenFree'
import SEO from '../components/SEO'
import VideoGallery from '../components/VideoGallery'
import CanonicalLink from '../components/CanonicalLink'

const isGlutenFree = (id) => id === '5GouEF1wWUol9v8LKlhaQJ'
const isFAQ = (id) =>
  id === '3VVioW8kUaEiUuVijI1AEj' || id === '7zpBmdQor07PppqOvxBYS4'
const isProduct = (id) =>
  id === '5fWz2kMjcisyLgS0MMEMWI' || id === '2o26829WsmrqsAcdAmm6r9'
const isRecipe = (id) =>
  id === '7JquXNnTsLdTaMI3U33iti' || id === '5vB55VWorM1DeFTnq39q8J'
const isAbout = (id) =>
  id === 'M8LXjnS5CJJjl7TJnNhZJ' || id === '350V4ZiFsFL0dfFpfgSLvc'

const containsHeading = (str) => str.length > 500

const PageBody = (props) =>
  !containsHeading(JSON.stringify(props)) ? (
    <Section center>
      <Leader {...props} />
    </Section>
  ) : (
    <Panel style={{ marginTop: '4rem' }}>
      <PanelContainer {...props} />
    </Panel>
  )

const Page = ({ data: { page, translate } }) => {
  const {
    contentful_id: id,
    title,
    description,
    image,
    cards,
    blocks,
    videoGallery,
    canonical,
  } = page
  const variantHeaderIds = [
    '4OjCxaJvQrzNfr7eNQ5iHI',
    'M8LXjnS5CJJjl7TJnNhZJ',
    '5GouEF1wWUol9v8LKlhaQJ',
    '3VVioW8kUaEiUuVijI1AEj',
  ]

  const headerProps = { title, image }
  const bodyProps = extractHTML(page, 'body')
  return (
    <App>
      <CanonicalLink canonical={canonical} />
      <HrefLangMeta {...translate} />
      <SEO {...{ title, description }} />
      {variantHeaderIds.includes(id) ? (
        <HeroVariant {...headerProps} />
      ) : (
        <Hero {...headerProps} />
      )}

      <Wrapper offset={!variantHeaderIds.includes(id)}>
        {bodyProps && <PageBody {...bodyProps} />}

        <Main>
          {isGlutenFree(id) && <GlutenFree locale={page.node_locale} />}

          {
            /** If it has cards */
            cards && (
              <Grid>
                {cards.map((card, i) => (
                  <CardKitchen
                    inverse={i % 2}
                    key={card.contentful_id}
                    {...card}
                  />
                ))}
              </Grid>
            )
          }

          {isProduct(id) && <Categories locale={page.node_locale} />}
          {isFAQ(id) && <Questions locale={page.node_locale} />}
          {isRecipe(id) && <Recipes locale={page.node_locale} />}

          {blocks && (
            <Grid>
              {blocks.map((block) => (
                <CardMediaLess
                  key={block.contentful_id}
                  small={blocks.length === 3}
                  {...block}
                />
              ))}
            </Grid>
          )}
        </Main>

        {videoGallery && <VideoGallery videos={videoGallery} />}
        {isAbout(id) && <Highlights locale={page.node_locale} />}

        {page.callToAction && <CallToAction {...page.callToAction} />}
      </Wrapper>
    </App>
  )
}

export const query = graphql`
  query contentfulPageByID($contentful_id: String, $node_locale: String) {
    translate: contentfulPage(
      contentful_id: { eq: $contentful_id }
      node_locale: { ne: $node_locale }
    ) {
      node_locale
      title
      slug
    }

    page: contentfulPage(
      contentful_id: { eq: $contentful_id }
      node_locale: { eq: $node_locale }
    ) {
      ...PageSEO
      ...CardDetail
      ...PagePhoto
      contentful_id
      node_locale
      canonical
      body {
        childContentfulRichText {
          html
        }
      }
      blocks {
        contentful_id
        title
        subtitle
        button
        url
      }
      videoGallery {
        title
        video
      }
      callToAction {
        title
        subtitle
        url
        button: buttonText
        body {
          childContentfulRichText {
            html
          }
        }
      }
    }
  }
`

export default Page
