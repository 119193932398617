import React from 'react';
import { graphql, StaticQuery } from 'gatsby';
import { filterByLocale } from '../helpers/iterators';
import { generateSlug } from '../helpers/slug';
import lang from '../../static/lang.json';
import Grid from './Grid';
import Card from './Card';

export const query = graphql`
    query {
        products: allContentfulVariant(filter: { isGlutenFree: { eq: true } }) {
            nodes {
                node_locale
                contentful_id
                title
                product {
                    title
                    category {
                        title
                    }
                }
                photo {
                    title
                    fluid(quality: 100) {
                        ...GatsbyContentfulFluid
                    }
                }
            }
        }
    }
`;

export default ({ locale }) => (
    <StaticQuery
        query={query}
        render={({ products }) => {
            const results = filterByLocale(products.nodes, locale);
            return (
                <Grid>
                    {results.map((variant, i) => {
                        if (!variant.product) return null;
                        const product = variant.product[0];
                        if (!product || !product.category) return null;

                        const settings = {
                            key: i,
                            image: variant.photo,
                            linkText: 'See Product',
                            name: variant.title,
                            glutenFree: true,
                            url: `${generateSlug(
                                [
                                    locale,
                                    lang.products[locale.toLowerCase()],
                                    product.category.title,
                                    product.title,
                                    variant.title,
                                ].join('/'),
                                true,
                            )}`,
                        };

                        return <Card {...settings} />;
                    })}
                </Grid>
            );
        }}
    />
);
