import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import styled from 'styled-components'
import { extractHTML, filterByLocale } from '../helpers/iterators'
import LocaleContext from './Locale'

const Definitions = styled.dl`
  box-sizing: border-box;
  margin: 1rem 0;
  padding: 2rem;
`

const DefinitionsTitle = styled.dt`
  background-color: #fff;
  font-size: 1.21em;
  font-weight: 900;
  padding: 3rem 3rem 0.75rem 3rem;
`

const DefinitionsDescription = styled.dd`
  background-color: #fff;
  margin: 0 0 1rem 0;
  padding: 0.25rem 3rem 2rem 3rem;

  p:first-of-type {
    margin-top: 0;
  }
  p,
  li {
    font-size: 0.9rem;
  }
`

const query = graphql`
  query {
    faq: allContentfulFaq {
      nodes {
        title
        node_locale
        description {
          childContentfulRichText {
            html
          }
        }
      }
    }
  }
`

const Questions = () => (
  <LocaleContext>
    {({ locale }) => (
      <StaticQuery
        query={query}
        render={({ faq }) => (
          <Definitions>
            {
              /** Will filter by locale based on Context API */
              filterByLocale(faq.nodes, locale).map((node) => (
                <React.Fragment key={node.title}>
                  <DefinitionsTitle>{node.title}</DefinitionsTitle>
                  <DefinitionsDescription>
                    <div {...extractHTML(node, 'description')} />
                  </DefinitionsDescription>
                </React.Fragment>
              ))
            }
          </Definitions>
        )}
      />
    )}
  </LocaleContext>
)

export default Questions
